'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var defaults = {
  AuthenticatingComponent: function AuthenticatingComponent() {
    return null;
  }, // dont render anything while authenticating
  FailureComponent: function FailureComponent() {
    return null;
  }, // dont render anything on failure of the predicate
  wrapperDisplayName: 'AuthWrapper'
};

exports.default = function (args) {
  var _defaults$args = _extends({}, defaults, args),
      AuthenticatingComponent = _defaults$args.AuthenticatingComponent,
      FailureComponent = _defaults$args.FailureComponent,
      wrapperDisplayName = _defaults$args.wrapperDisplayName;

  // Wraps the component that needs the auth enforcement


  function wrapComponent(DecoratedComponent) {
    var _class, _temp;

    var displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';

    var UserAuthWrapper = (_temp = _class = function (_Component) {
      _inherits(UserAuthWrapper, _Component);

      function UserAuthWrapper() {
        _classCallCheck(this, UserAuthWrapper);

        return _possibleConstructorReturn(this, (UserAuthWrapper.__proto__ || Object.getPrototypeOf(UserAuthWrapper)).apply(this, arguments));
      }

      _createClass(UserAuthWrapper, [{
        key: 'render',
        value: function render() {
          var _props = this.props,
              isAuthenticated = _props.isAuthenticated,
              isAuthenticating = _props.isAuthenticating;

          if (isAuthenticated) {
            return _react2.default.createElement(DecoratedComponent, this.props);
          } else if (isAuthenticating) {
            return _react2.default.createElement(AuthenticatingComponent, this.props);
          } else {
            return _react2.default.createElement(FailureComponent, this.props);
          }
        }
      }]);

      return UserAuthWrapper;
    }(_react.Component), _class.displayName = wrapperDisplayName + '(' + displayName + ')', _class.propTypes = {
      isAuthenticated: _propTypes2.default.bool,
      isAuthenticating: _propTypes2.default.bool
    }, _class.defaultProps = {
      isAuthenticating: false
    }, _temp);


    return (0, _hoistNonReactStatics2.default)(UserAuthWrapper, DecoratedComponent);
  }

  return wrapComponent;
};