'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _url = require('url');

var _url2 = _interopRequireDefault(_url);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaults = {
  redirectQueryParamName: 'redirect',
  locationSelector: function locationSelector(_ref) {
    var location = _ref.location;
    return location;
  }
};

exports.default = function (args) {
  var _defaults$args = _extends({}, defaults, args),
      redirectQueryParamName = _defaults$args.redirectQueryParamName,
      locationSelector = _defaults$args.locationSelector;

  var getRedirectQueryParam = function getRedirectQueryParam(props) {
    var location = locationSelector(props);
    return location.query[redirectQueryParamName];
  };

  var createRedirectLoc = function createRedirectLoc(allowRedirectBack) {
    return function (props, redirectPath) {
      var location = locationSelector(props);
      var redirectLoc = _url2.default.parse(redirectPath, true);

      var query = void 0;

      if (allowRedirectBack) {
        query = _defineProperty({}, redirectQueryParamName, '' + location.pathname + location.search + location.hash);
      } else {
        query = {};
      }

      query = _extends({}, query, redirectLoc.query);

      return {
        pathname: redirectLoc.pathname,
        hash: redirectLoc.hash,
        query: query
      };
    };
  };

  return {
    getRedirectQueryParam: getRedirectQueryParam,
    createRedirectLoc: createRedirectLoc
  };
};