'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOnEnter = exports.connectedReduxRedirect = exports.connectedRouterRedirect = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _locationHelper = require('../history3/locationHelper');

var _locationHelper2 = _interopRequireDefault(_locationHelper);

var _redirect = require('../helper/redirect');

var _redirect2 = _interopRequireDefault(_redirect);

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _redirectUtil = (0, _redirect2.default)({
  locationHelperBuilder: _locationHelper2.default,
  getRouterRedirect: function getRouterRedirect(_ref) {
    var router = _ref.router;
    return router.replace;
  }
});

var connectedRouterRedirect = _redirectUtil.connectedRouterRedirect,
    connectedReduxRedirect = _redirectUtil.connectedReduxRedirect;
exports.connectedRouterRedirect = connectedRouterRedirect;
exports.connectedReduxRedirect = connectedReduxRedirect;


var onEnterDefaults = {
  allowRedirectBack: true,
  authenticatingSelector: function authenticatingSelector() {
    return false;
  },
  redirectQueryParamName: 'redirect'
};

var createOnEnter = exports.createOnEnter = function createOnEnter(config) {
  var _onEnterDefaults$conf = _extends({}, onEnterDefaults, config),
      authenticatedSelector = _onEnterDefaults$conf.authenticatedSelector,
      authenticatingSelector = _onEnterDefaults$conf.authenticatingSelector,
      redirectPath = _onEnterDefaults$conf.redirectPath,
      allowRedirectBack = _onEnterDefaults$conf.allowRedirectBack,
      redirectQueryParamName = _onEnterDefaults$conf.redirectQueryParamName;

  var redirectPathSelector = void 0;
  if (typeof redirectPath === 'string') {
    redirectPathSelector = function redirectPathSelector() {
      return redirectPath;
    };
  } else if (typeof redirectPath === 'function') {
    redirectPathSelector = redirectPath;
  } else {
    (0, _invariant2.default)(false, 'redirectPath must be either a string or a function');
  }

  var allowRedirectBackFn = void 0;
  if (typeof allowRedirectBack === 'boolean') {
    allowRedirectBackFn = function allowRedirectBackFn() {
      return allowRedirectBack;
    };
  } else if (typeof allowRedirectBack === 'function') {
    allowRedirectBackFn = allowRedirectBack;
  } else {
    (0, _invariant2.default)(false, 'allowRedirectBack must be either a boolean or a function');
  }

  return function (store, nextState, replace) {
    var _locationHelperBuilde = (0, _locationHelper2.default)({
      redirectQueryParamName: redirectQueryParamName
    }),
        createRedirectLoc = _locationHelperBuilde.createRedirectLoc;

    var isAuthenticated = authenticatedSelector(store.getState(), nextState);
    var isAuthenticating = authenticatingSelector(store.getState(), nextState);

    if (!isAuthenticated && !isAuthenticating) {
      var _redirectPath = redirectPathSelector(store.getState(), nextState);
      replace(createRedirectLoc(allowRedirectBackFn(nextState, _redirectPath))(nextState, _redirectPath));
    }
  };
};