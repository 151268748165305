'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactRedux = require('react-redux');

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

var _authWrapper = require('../authWrapper');

var _authWrapper2 = _interopRequireDefault(_authWrapper);

var _redirect = require('../redirect');

var _redirect2 = _interopRequireDefault(_redirect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var connectedDefaults = {
  authenticatingSelector: function authenticatingSelector() {
    return false;
  },
  allowRedirectBack: true,
  FailureComponent: _redirect2.default,
  redirectQueryParamName: 'redirect'
};

exports.default = function (_ref) {
  var locationHelperBuilder = _ref.locationHelperBuilder,
      getRouterRedirect = _ref.getRouterRedirect;


  var connectedRouterRedirect = function connectedRouterRedirect(args) {
    var allArgs = _extends({}, connectedDefaults, args);
    var FailureComponent = allArgs.FailureComponent,
        redirectPath = allArgs.redirectPath,
        authenticatedSelector = allArgs.authenticatedSelector,
        authenticatingSelector = allArgs.authenticatingSelector,
        allowRedirectBack = allArgs.allowRedirectBack,
        redirectQueryParamName = allArgs.redirectQueryParamName;

    var _locationHelperBuilde = locationHelperBuilder({
      redirectQueryParamName: redirectQueryParamName
    }),
        createRedirectLoc = _locationHelperBuilde.createRedirectLoc;

    var redirectPathSelector = void 0;
    if (typeof redirectPath === 'string') {
      redirectPathSelector = function redirectPathSelector() {
        return redirectPath;
      };
    } else if (typeof redirectPath === 'function') {
      redirectPathSelector = redirectPath;
    } else {
      (0, _invariant2.default)(false, 'redirectPath must be either a string or a function');
    }

    var allowRedirectBackFn = void 0;
    if (typeof allowRedirectBack === 'boolean') {
      allowRedirectBackFn = function allowRedirectBackFn() {
        return allowRedirectBack;
      };
    } else if (typeof allowRedirectBack === 'function') {
      allowRedirectBackFn = allowRedirectBack;
    } else {
      (0, _invariant2.default)(false, 'allowRedirectBack must be either a boolean or a function');
    }

    var redirect = function redirect(replace) {
      return function (props, path) {
        return replace(createRedirectLoc(allowRedirectBackFn(props, path))(props, path));
      };
    };

    var ConnectedFailureComponent = (0, _reactRedux.connect)(function (state, ownProps) {
      return {
        redirect: redirect(getRouterRedirect(ownProps))
      };
    })(FailureComponent);

    return function (DecoratedComponent) {
      return (0, _reactRedux.connect)(function (state, ownProps) {
        return {
          redirectPath: redirectPathSelector(state, ownProps),
          isAuthenticated: authenticatedSelector(state, ownProps),
          isAuthenticating: authenticatingSelector(state, ownProps)
        };
      })((0, _authWrapper2.default)(_extends({}, allArgs, { FailureComponent: ConnectedFailureComponent }))(DecoratedComponent));
    };
  };

  var connectedReduxRedirect = function connectedReduxRedirect(args) {
    var allArgs = _extends({}, connectedDefaults, args);
    var FailureComponent = allArgs.FailureComponent,
        redirectPath = allArgs.redirectPath,
        authenticatedSelector = allArgs.authenticatedSelector,
        authenticatingSelector = allArgs.authenticatingSelector,
        allowRedirectBack = allArgs.allowRedirectBack,
        redirectAction = allArgs.redirectAction,
        redirectQueryParamName = allArgs.redirectQueryParamName;

    var _locationHelperBuilde2 = locationHelperBuilder({
      redirectQueryParamName: redirectQueryParamName
    }),
        createRedirectLoc = _locationHelperBuilde2.createRedirectLoc;

    var redirectPathSelector = void 0;
    if (typeof redirectPath === 'string') {
      redirectPathSelector = function redirectPathSelector() {
        return redirectPath;
      };
    } else if (typeof redirectPath === 'function') {
      redirectPathSelector = redirectPath;
    } else {
      (0, _invariant2.default)(false, 'redirectPath must be either a string or a function');
    }

    var allowRedirectBackFn = void 0;
    if (typeof allowRedirectBack === 'boolean') {
      allowRedirectBackFn = function allowRedirectBackFn() {
        return allowRedirectBack;
      };
    } else if (typeof allowRedirectBack === 'function') {
      allowRedirectBackFn = allowRedirectBack;
    } else {
      (0, _invariant2.default)(false, 'allowRedirectBack must be either a boolean or a function');
    }

    var createRedirect = function createRedirect(dispatch) {
      return {
        redirect: function redirect(props, path) {
          return dispatch(redirectAction(createRedirectLoc(allowRedirectBackFn(props, path))(props, path)));
        }
      };
    };

    var ConnectedFailureComponent = (0, _reactRedux.connect)(null, createRedirect)(FailureComponent);

    return function (DecoratedComponent) {
      return (0, _reactRedux.connect)(function (state, ownProps) {
        return {
          redirectPath: redirectPathSelector(state, ownProps),
          isAuthenticated: authenticatedSelector(state, ownProps),
          isAuthenticating: authenticatingSelector(state, ownProps)
        };
      })((0, _authWrapper2.default)(_extends({}, allArgs, { FailureComponent: ConnectedFailureComponent }))(DecoratedComponent));
    };
  };

  return {
    connectedRouterRedirect: connectedRouterRedirect,
    connectedReduxRedirect: connectedReduxRedirect
  };
};